import { classNames } from "repoV2/utils/common/render/classNames";
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import React from "react";
import { HiHome } from "@react-icons/all-files/hi/HiHome";
import useMediaQuery from "@restart/hooks/cjs/useMediaQuery";
import { useScrollToSectionOnMount } from "@Utils/hooks/rendering";
import { INavbar } from "../../INavbar";
import styles from "./navbarLinktemp.module.scss";
import { Account } from "../../components/Account";
import { Notification } from "../../components/Notification";

const NavbarLinktemp = ({
    loggedInState,
    notificationProps,
    accountProps,
    currentPathIsHostPage,
    navbarItems,
    rootElement,
}: INavbar.IChildProps): JSX.Element => {
    // Automatically scroll to the section mentioned in url after "#" after 1200 ms.
    // why 1200 ms?? - to wait for page to load
    useScrollToSectionOnMount(1200);
    const isMobile: boolean = useMediaQuery("(max-width: 768px)");
    const loginStatusStyle =
        loggedInState === "loggedIn" ? styles.loggedIn : styles.notLoggedIn;

    return (
        <div
            className={classNames(styles.root, loginStatusStyle)}
            ref={rootElement}
        >
            <div className={styles.container}>
                <div className={styles.leftSubContainer}>
                    <a
                        className={classNames(
                            styles.hostPageButton,
                            loginStatusStyle
                        )}
                        {...navbarItems.home.props}
                    >
                        {!currentPathIsHostPage() ? null : (
                            <div
                                className={classNames(
                                    styles.homeIcon,
                                    loginStatusStyle
                                )}
                            >
                                <HiHome />
                            </div>
                        )}
                        {navbarItems.home.content}
                    </a>
                </div>
                <div className={styles.rightSubContainer}>
                    <Notification
                        {...notificationProps}
                        notificationIconColorScheme={classNames(
                            styles.navbarItemInvertedColorScheme,
                            loginStatusStyle
                        )}
                    />
                    <div className="position-relative">
                        <Account
                            {...accountProps}
                            accountIconColorScheme={classNames(
                                styles.navbarItemColorScheme,
                                loginStatusStyle
                            )}
                        />
                    </div>
                    {isMobile ? (
                        <>
                            {navbarItems.getInTouch.showItem ? (
                                <div
                                    className={classNames(
                                        !isMobile && styles.iconButton,
                                        styles.button
                                    )}
                                    {...navbarItems.getInTouch.props}
                                >
                                    {isMobile ? (
                                        <FaPhoneAlt />
                                    ) : (
                                        navbarItems.getInTouch.content
                                    )}
                                </div>
                            ) : null}
                        </>
                    ) : null}
                    {navbarItems.login.showItem ? (
                        <a
                            className={classNames(
                                styles.loginButton,
                                loginStatusStyle
                            )}
                            {...navbarItems.login.props}
                        >
                            {navbarItems.login.content}
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default NavbarLinktemp;
